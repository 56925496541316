import React from "react";
import { Nav, NavLink, NavMenu } from "react-router-dom";
import { useState } from "react";
export default function Navbar() {
  const [show, setShow] = useState(false);
  return (
    <div>
      <header className="main-header-area">
        {/* Start Navbar Area */}
        <div className="navbar-area">
          <div className="main-responsive-nav">
            <div className="container">
              <div className="main-responsive-menu mean-container">
                <div className="mean-bar">
                  <a
                    onClick={() => setShow((s) => !s)}
                    href="#"
                    className="meanmenu-reveal"
                    style={{
                      background: "",
                      color: "",
                      right: 0,
                      left: "auto",
                    }}
                  >
                    <span>
                      <span>
                        <span />
                      </span>
                    </span>
                  </a>

                  <nav className="mean-nav">
                    <ul
                      className="navbar-nav ms-auto"
                      style={{ display: show ? "block" : "none" }}
                    >
                      <li className="nav-item">
                        <NavLink to="/" className="nav-link">
                          Home
                        </NavLink>
                      </li>

                      <li className="nav-item">
                        <NavLink to="/about" className="nav-link">
                          About
                        </NavLink>
                      </li>
                      <li className="nav-item" aria-labelledby="navbarDropdown">
                        <a href="#" className="nav-link">
                          Services
                        </a>
                        <ul className="dropdown-menu">
                          <li className="nav-item">
                            <a href="#" className="nav-link">
                              Web 3 Development
                            </a>
                          </li>
                          <li className="nav-item">
                            <a href="#" className="nav-link">
                              Blockchain Development
                            </a>
                          </li>
                          <li className="nav-item">
                            <a href="#" className="nav-link">
                              NFT Development
                            </a>
                          </li>
                          <li className="nav-item">
                            <a href="#" className="nav-link">
                              Mobile App Development
                            </a>
                          </li>
                          <li className="nav-item">
                            <a href="#" className="nav-link">
                              PWA Development
                            </a>
                          </li>
                          <li className="nav-item">
                            <a href="#" className="nav-link">
                              Web App Development
                            </a>
                          </li>
                          <li className="nav-item">
                            <a href="#" className="nav-link">
                              Product Prototype
                            </a>
                          </li>
                          <li className="nav-item">
                            <a href="#" className="nav-link">
                              Cloud And DevOps
                            </a>
                          </li>
                          <li className="nav-item">
                            <a href="#" className="nav-link">
                              Software Testing
                            </a>
                          </li>
                          <li className="nav-item">
                            <a href="#" className="nav-link">
                              Digital Marketing
                            </a>
                          </li>
                        </ul>
                        <a
                          className="mean-expand"
                          href="#"
                          id="navbarDropdown"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          +
                        </a>
                      </li>
                      <li className="nav-item mean-last">
                        <NavLink to="/contact" className="nav-link">
                          Contact
                        </NavLink>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className="logo">
                  <a href="./">
                    <img
                      src="assets/images/ulticus-logo-welcome-to-innovate-sagar-jain-world_s-no.1-guaranteed-roi-it-company-main-light.png"
                      alt="ulticus-logo-welcome-to-innovate-sagar-jain-world_s - no.1-guaranteed-roi-it-company-main"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="main-navbar navbar-with-black-color">
            <div className="container-fluid">
              <nav className="navbar navbar-expand-md navbar-light">
                <a className="navbar-brand" href="./">
                  <img
                    src="assets/images/ulticus-logo-welcome-to-innovate-sagar-jain-world_s-no.1-guaranteed-roi-it-company-main-light.png"
                    alt="ulticus-logo-welcome-to-innovate-sagar-jain-world_s - no.1-guaranteed-roi-it-company-main"
                  />
                </a>
                <div
                  className="collapse navbar-collapse mean-menu"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav ms-auto">
                    <li className="nav-item">
                      <NavLink to="/" className="nav-link">
                        Home
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <a href="#" className="nav-link">
                        Services
                      </a>
                      <ul className="dropdown-menu">
                        <li className="nav-item">
                          <a href="#" className="nav-link">
                            Web 3 Development
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="#" className="nav-link">
                            Blockchain Development
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="#" className="nav-link">
                            NFT Development
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="#" className="nav-link">
                            Mobile App Development
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="#" className="nav-link">
                            PWA Development
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="#" className="nav-link">
                            Web App Development
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="#" className="nav-link">
                            Product Prototype
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="#" className="nav-link">
                            Cloud And DevOps
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="#" className="nav-link">
                            Software Testing
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="#" className="nav-link">
                            Digital Marketing
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/about" className="nav-link">
                        About
                      </NavLink>
                    </li>

                    <li className="nav-item ">
                      <NavLink to="/contact" className="nav-link">
                        Contact
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
        {/* End Navbar Area */}
      </header>
    </div>
  );
}
