import React from "react";
import "../Slider/Slider.css";

// import { Swiper, SwiperSlide } from "swiper/react";
// import { EffectFade } from "swiper";
// import "swiper/css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Slider = () => {
  let styles = {
    // margin: "auto",
    // width: "50%",
    // height: "20%",
  };
  return (
    <div>
      <div className="services-area margin-zero ptb-100" id="services-area">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-12">
              <div className="services-section-content" data-aos-once="true">
                <div className="services-bg-text">SERVICES</div>
                <span>SERVICES</span>
                <h3>
                  We Provide the Best Quality Services With Guaranteed{" "}
                  <b>ROI</b> <span className="" />
                </h3>
                <p>
                  We are technology solutions providing company all over the
                  world doing over 11+ years.
                </p>
                <div className="services-section-btn"></div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12">
              <div className="services-slides owl-theme">
                <div className="slider" id="slider">
                  <Carousel
                    showArrows={true}
                    showIndicators={true}
                    showThumbs={true}
                    useKeyboardArrows={true}
                    // spaceBetween={30}
                    // slidesPerView={3}
                    // grabCursor={true}
                    // scrollbar={{ draggable: true }}
                    // pagination={{ clickable: true }}
                    // className="portfolio-slider"
                  >
                    {/* <Carousel> */}
                    <div className="slide_one">
                      <div className="services-item">
                        <div className="services-image">
                          <a href="#">
                            <img
                              src="https://templates.envytheme.com/coze/default/assets/images/services/services-1.jpg"
                              alt="image"
                            />
                          </a>
                        </div>
                        <div className="services-content">
                          <h3>
                            <a href="#">IOS Development</a>
                          </h3>
                          <p>
                            iPhone to iPad to Apple watch to Apple TV a hub of
                            iPhone application development services
                          </p>
                          <a href="#" className="services-btn">
                            View More
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* </SwiperSlide> */}

                    {/* <SwiperSlide> */}
                    <div className="slide_two">
                      <div className="services-item">
                        <div className="services-image">
                          <a href="#">
                            <img
                              src="https://templates.envytheme.com/coze/default/assets/images/services/services-2.jpg"
                              alt="image"
                            />
                          </a>
                        </div>
                        <div className="services-content">
                          <h3>
                            <a href="#">Android Development</a>
                          </h3>
                          <p>
                            From Consumer to Startup to Enterprise we've
                            developed Android apps for all
                          </p>
                          <a href="#" className="services-btn">
                            View More
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* </SwiperSlide> */}
                    {/* <SwiperSlide> */}
                    <div className="slide_three">
                      <div className="services-item">
                        <div className="services-image">
                          <a href="#">
                            <img
                              src="https://templates.envytheme.com/coze/default/assets/images/services/services-3.jpg"
                              alt="image"
                            />
                          </a>
                        </div>
                        <div className="services-content">
                          <h3>
                            <a href="#">Web Development</a>
                          </h3>
                          <p>
                            Completely customizable high-quality robust
                            websites...
                          </p>
                          <a href="#" className="services-btn">
                            View More
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* </SwiperSlide> */}
                    {/* <SwiperSlide> */}
                    <div className="slide_four">
                      <div className="services-item">
                        <div className="services-image">
                          <a href="#">
                            <img
                              src="https://templates.envytheme.com/coze/default/assets/images/services/services-4.jpg"
                              alt="image"
                            />
                          </a>
                        </div>
                        <div className="services-content">
                          <h3>
                            <a href="#">NFT Development</a>
                          </h3>
                          <p>
                            Looking to monetize all your physical world
                            collectibles? experience the power of our end-to-end
                            nft development services and let’s go
                          </p>
                          <a href="#" className="services-btn">
                            View More
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* </SwiperSlide> */}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Swiper
        spaceBetween={30}
        slidesPerView={3}
        grabCursor={true}
        className="portfolio-slider"
      >
        <div className="services-slides ">
          <SwiperSlide>
            <div className="services-item">
              <div className="services-image">
                <img
                  src="https://templates.envytheme.com/coze/default/assets/images/services/services-1.jpg"
                  alt=""
                />
              </div>
              <div className="services-content">
                <h3>
                  <a href="">IOS Development</a>
                </h3>
                <p>
                  iPhone to iPad to Apple watch to Apple TV a hub of iPhone
                  application development services
                </p>
                <a href="#" className="services-btn">
                  View More
                </a>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="services-item">
              <div className="services-image">
                <a href="#">
                  <img
                    src="https://templates.envytheme.com/coze/default/assets/images/services/services-2.jpg"
                    alt="image"
                  />
                </a>
              </div>
              <div className="services-content">
                <h3>
                  <a href="#">Android Development</a>
                </h3>
                <p>
                  From Consumer to Startup to Enterprise we've developed Android
                  apps for all
                </p>
                <a href="#" className="services-btn">
                  View More
                </a>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="services-item">
              <div className="services-image">
                <a href="services-details.html">
                  <img
                    src="https://templates.envytheme.com/coze/default/assets/images/services/services-3.jpg"
                    alt="image"
                  />
                </a>
              </div>
              <div className="services-content">
                <h3>
                  <a href="services-details.html">Web Development</a>
                </h3>
                <p>Completely customizable high-quality robust websites...</p>
                <a href="services-details.html" className="services-btn">
                  View More
                </a>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="services-item">
              <div className="services-image">
                <a href="services-details.html">
                  <img
                    src="https://templates.envytheme.com/coze/default/assets/images/services/services-4.jpg"
                    alt="image"
                  />
                </a>
              </div>
              <div className="services-content">
                <h3>
                  <a href="services-details.html">NFT Development</a>
                </h3>
                <p>
                  Looking to monetize all your physical world collectibles?
                  experience the power of our end-to-end nft development
                  services and let’s go
                </p>
                <a href="services-details.html" className="services-btn">
                  View More
                </a>
              </div>
            </div>
          </SwiperSlide>
        </div>
      </Swiper> */}
    </div>
  );
};

export default Slider;
