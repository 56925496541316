import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, BrowserRouter }
from 'react-router-dom';
import Home from './Components/Home/Home';
import About from './Components/About/About';
import Slider from './Components/Slider/Slider';
import Navbar from './Components/Navbar/Navbar';

// import Blogs from './pages/blogs';
// import SignUp from './pages/signup';
import Contact from './Components/Contact/Contact';

function App() {
    return ( 
    <BrowserRouter>
    {/* <Navbar></Navbar> */}
      <Routes>
        {/* <Route><Navbar></Navbar></Route> */}
        
        <Route  path='/' element={<Home/>}></Route>
        <Route  path='/about' element={<About/>}></Route>        
        <Route  path='/contact' element={<Contact/>}></Route>
      </Routes>
    </BrowserRouter>
 
    );
}

export default App;