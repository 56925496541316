import React from "react";
import { Nav, NavLink, NavMenu } from "react-router-dom";
import { useState } from "react";
import Navbar from "../Navbar/Navbar";

function About() {
  const [show, setShow] = useState(false);
  return (
    <div>
      <div>
        {/* Start Navbar Area */}
        <Navbar></Navbar>
        {/* End Navbar Area */}
        {/* Start Page Banner Area */}
        <div
          className="page-banner-area bg-3 jarallax"
          data-jarallax='{"speed": 0.3}'
        >
          <div className="container">
            <div
              className="page-banner-content"
              // data-aos="fade-right"
              // data-aos-delay={50}
              // data-aos-duration={500}
              data-aos-once="true"
            >
              <h2>About us</h2>
              <ul>
                <li>
                  <a href="./">Home</a>
                </li>
                <li>About Us</li>
              </ul>
            </div>
          </div>
        </div>
        {/* End Page Banner Area */}
        {/* Start About Area */}
        <div className="about-area border-none pt-100 pb-75">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="about-wrap-image">
                  <img
                    src="https://templates.envytheme.com/coze/default/assets/images/about/about-3.jpg"
                    alt="image"
                  />
                  {/* <img src="assets/images/about/about-3.jpg" alt="image" > */}
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div
                  className="about-wrap-content"
                  // data-aos="fade-up"
                  // data-aos-delay={80}
                  // data-aos-duration={800}
                  data-aos-once="true"
                >
                  <div className="about-bg-text">ABOUT US</div>
                  <span>About Us</span>
                  <h3>
                    Build Your Company With Us
                    <span className="" />
                  </h3>
                  <p>
                    Ultimate Infoways working with innovative companies and
                    passionate achievers as we strive to be the best in the
                    enterprise web3, web2 and mobile design and development.
                  </p>
                </div>
              </div>
            </div>
            <div className="about-inner-box">
              <div className="row justify-content-center">
                <div className="col-lg-4 col-md-6">
                  <div
                    className="single-about-card"
                    // data-aos="fade-up"
                    // data-aos-delay={80}
                    // data-aos-duration={800}
                    data-aos-once="true"
                  >
                    <h3>Our Vision</h3>
                    <p>
                      No matter which industry you belong to, every business
                      must become digital.
                    </p>
                    {/* <div class="about-btn">
                                <a href="about-style-1.html" class="default-btn">Know More About Us</a>
                            </div> */}
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div
                    className="single-about-card"
                    // data-aos="fade-down"
                    // data-aos-delay={70}
                    // data-aos-duration={700}
                    data-aos-once="true"
                  >
                    <h3>Our Mission</h3>
                    <p>
                      Our mission is to enhance business growth of our customers
                      with creative design, development and to deliver market
                      defining high quality solutions that create value and
                      reliable competitive advantage to customers around the
                      globe.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div
                    className="single-about-card"
                    // data-aos="fade-up"
                    // data-aos-delay={80}
                    // data-aos-duration={800}
                    data-aos-once="true"
                  >
                    <div className="card-image" data-tilt>
                      <img
                        src="https://templates.envytheme.com/coze/default/assets/images/about/about-4.jpg"
                        alt="image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="about-circle-shape">
            <img src="assets/images/about/about-circle.png" alt="image" />
          </div>
        </div>
        {/* End About Area */}
        {/* Start Choose Area */}
        <div className="choose-area pt-100 pb-75">
          <div className="container">
            <div className="section-title section-style-two">
              <div className="section-bg-text">PROCESS</div>
              <span>WORK PROCESS</span>
              <h2>
                We are following scrum framework and agile methodology{" "}
                <span className="" />
              </h2>
              <p>
                We are leading technology solutions providing company all over
                the world doing over 11+ years.
              </p>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-3 col-sm-6">
                <div
                  className="single-choose-card"
                  // data-aos="fade-up"
                  // data-aos-delay={50}
                  // data-aos-duration={500}
                  data-aos-once="true"
                >
                  <div className="choose-image">
                    <a href="services-details.html">
                      <img
                        src="https://templates.envytheme.com/coze/default/assets/images/choose/choose-1.png"
                        alt="image"
                      />
                    </a>
                    <div className="number">1</div>
                  </div>
                  <div className="choose-content">
                    <h3>
                      <a href="#">Discussion</a>
                    </h3>
                    <p />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div
                  className="single-choose-card"
                  // data-aos="fade-up"
                  // data-aos-delay={60}
                  // data-aos-duration={600}
                  data-aos-once="true"
                >
                  <div className="choose-image">
                    <a href="services-details.html">
                      <img
                        src="https://templates.envytheme.com/coze/default/assets/images/choose/choose-2.png"
                        alt="image"
                      />
                    </a>
                    <div className="number">2</div>
                  </div>
                  <div className="choose-content">
                    <h3>
                      <a href="#">Testing &amp; Trying</a>
                    </h3>
                    <p />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div
                  className="single-choose-card"
                  // data-aos="fade-up"
                  // data-aos-delay={70}
                  // data-aos-duration={700}
                  data-aos-once="true"
                >
                  <div className="choose-image">
                    <a href="services-details.html">
                      <img
                        src="https://templates.envytheme.com/coze/default/assets/images/choose/choose-3.png"
                        alt="image"
                      />
                    </a>
                    <div className="number">3</div>
                  </div>
                  <div className="choose-content">
                    <h3>
                      <a href="#">Ideas &amp; Concept</a>
                    </h3>
                    <p />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div
                  className="single-choose-card"
                  // data-aos="fade-up"
                  // data-aos-delay={80}
                  // data-aos-duration={800}
                  data-aos-once="true"
                >
                  <div className="choose-image">
                    <a href="services-details.html">
                      <img
                        src="https://templates.envytheme.com/coze/default/assets/images/choose/choose-4.png"
                        alt="image"
                      />
                    </a>
                    <div className="number">4</div>
                  </div>
                  <div className="choose-content">
                    <h3>
                      <a href="#">Execute &amp; Install</a>
                    </h3>
                    <p />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Choose Area */}
        {/* End Talk Area */}
        {/* End Footer Area */}
        <footer className="footer-area with-black-background margin-zero pt-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-3 col-md-6">
                <div
                  className="single-footer-widget"
                  // data-aos="fade-up"
                  // data-aos-delay={50}
                  // data-aos-duration={500}
                  data-aos-once="true"
                >
                  <div className="widget-logo">
                    <a href="./">
                      <img
                        src="assets/images/ulticus-logo-welcome-to-innovate-sagar-jain-world_s-no.1-guaranteed-roi-it-company-main-light.png"
                        alt="image"
                      />
                    </a>
                  </div>
                  <p>
                    Ultimate Infoways invites the most innovative minds, the
                    most talented individuals and the most passionate achievers
                    to join us as we strive to be the best in the web content
                    management, marketing automation and enterprise eCommerce
                    spaces
                  </p>
                  <ul className="widget-social">
                    <li>
                      <a
                        href="https://www.facebook.com/EnvyTheme"
                        target="_blank"
                      >
                        <i className="ri-facebook-fill" />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/?lang=en" target="_blank">
                        <i className="ri-twitter-fill" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/" target="_blank">
                        <i className="ri-youtube-fill" />
                      </a>
                    </li>
                    <li>
                      <a href="https://vimeo.com/" target="_blank">
                        <i className="ri-vimeo-fill" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/" target="_blank">
                        <i className="ri-instagram-line" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div class="col-lg-3 col-md-6">
                    <div class="single-footer-widget ps-5" data-aos="fade-up" data-aos-delay="60" data-aos-duration="600" data-aos-once="true">
                        <h3>Links</h3>

                        <ul class="quick-links">
                            <li><a href="about-style-1.html">About Us</a></li>
                            <li><a href="services-style-2.html">Services</a></li>
                            <li><a href="blog-style-1.html">News</a></li>
                            <li><a href="pricing.html">Pricing</a></li>
                            <li><a href="projects.html">Projects</a></li>
                        </ul>
                    </div>
                </div> */}
              <div className="col-lg-3 col-md-6">
                <div
                  className="single-footer-widget ps-5"
                  // data-aos="fade-up"
                  // data-aos-delay={70}
                  // data-aos-duration={700}
                  data-aos-once="true"
                >
                  {/* <h3>Pages</h3> */}
                  <ul className="quick-links">
                    {/* <li><a href="contact.html">Contact Us</a></li>
                            <li><a href="purchase-guide.html">Purchase Guide</a></li>
                            <li><a href="faq.html">FAQ's</a></li>
                            <li><a href="terms-of-service.html">Terms of Service</a></li>
                            <li><a href="privacy-policy.html">Privacy Policy</a></li> */}
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div
                  className="single-footer-widget"
                  // data-aos="fade-up"
                  // data-aos-delay={80}
                  // data-aos-duration={800}
                  data-aos-once="true"
                >
                  <h3>Subscribe Newsletter</h3>
                  <form className="newsletter-form" data-bs-toggle="validator">
                    <input
                      type="email"
                      className="input-newsletter"
                      placeholder="Enter your email"
                      name="EMAIL"
                      required
                      autoComplete="off"
                    />
                    <button type="submit" className="default-btn">
                      Subscribe
                    </button>
                    <div id="validator-newsletter" className="form-result" />
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright-area">
            <div className="container">
              <div className="copyright-area-content">
                <p>
                  Copyright @ Ultimate Infoways All Rights Reserved
                  <a href="https://envytheme.com/" target="_blank">
                    {" "}
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="footer-shape-1">
            <img src="assets/images/footer/footer-shape-1.png" alt="image" />
          </div>
          <div className="footer-shape-2">
            <img src="assets/images/footer/footer-shape-2.png" alt="image" />
          </div>
          <div className="footer-shape-3">
            <img src="assets/images/footer/footer-shape-3.png" alt="image" />
          </div>
        </footer>
        {/* End Footer Area */}
      </div>
    </div>
  );
}

export default About;
