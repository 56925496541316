import React from 'react';

import ReactDOM from 'react-dom';
// import * as ReactDOMClient from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import PWAPrompt from 'react-ios-pwa-prompt'
// import ReactDom from 'react-dom';
// import {BrowserRouter} from 'react-router-dom'
// import { BrowserRouter } from 'react-router-dom';


// // Working
// const root = document.getElementById('root');
// const ReactRoot = ReactDOMClient.createRoot(root)
// ReactRoot.render(<App tab="./" />);


// ReactRoot.render(<PWAPrompt/>);
// ReactRoot.render(<PWAPrompt copyTitle='Welcome to Ulticus' timesToShow={3} />);
// ReactRoot.render(<PWAPrompt  copyTitle="Welcome To Ulticus"/>);



// const Pwa = document.getElementById('root');
// const ReactRoots = ReactDOMClient.createRoot(Pwa)
// ReactRoots.render(<PWAPrompt copyTitle='Welcome to Ulticus' timesToShow={3} />);

//working
// ReactDOM.render(
// <React.StrictMode> 
//     <App />
//     <PWAPrompt copyTitle="Welcome To innovate" copyClosePrompt="Close" timesToShow={5}/>
// </React.StrictMode>
ReactDOM.render(
  <React.StrictMode>
    <App />
    <PWAPrompt copyTitle="Welcome To innovate" copyClosePrompt="Close" timesToShow={5}/>
  </React.StrictMode>,
  document.getElementById('root')
 );//


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
