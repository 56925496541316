import React from "react";
import emailjs from "emailjs-com";
import { useEffect, useState } from "react";
import { Nav, NavLink, NavMenu } from "react-router-dom";
import { useRef } from "react";
// import { useState } from "react";

function Contact() {
  const [show, setShow] = useState(false);
  const form = useRef();
  const [done, setDone] = useState(false);
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_br9nk9g",
        "template_0lo7enw",
        form.current,
        "80Lc-vG3uEzQcvt5u"
      )
      .then(
        (result) => {
          console.log(result.text);
          setDone(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div>
      <div>
        <div className="navbar-area">
          <div className="main-responsive-nav">
            <div className="container">
              <div className="main-responsive-menu mean-container">
                <div className="mean-bar">
                  <a
                    onClick={() => setShow((s) => !s)}
                    href="#"
                    className="meanmenu-reveal"
                    style={{
                      background: "",
                      color: "",
                      right: 0,
                      left: "auto",
                    }}
                  >
                    <span>
                      <span>
                        <span />
                      </span>
                    </span>
                  </a>

                  <nav className="mean-nav">
                    <ul
                      className="navbar-nav ms-auto"
                      style={{ display: show ? "block" : "none" }}
                    >
                      <li className="nav-item">
                        <NavLink to="/" className="nav-link">
                          Home
                        </NavLink>
                      </li>

                      <li className="nav-item">
                        <NavLink to="/about" className="nav-link">
                          About
                        </NavLink>
                      </li>
                      <li className="nav-item" aria-labelledby="navbarDropdown">
                        <a href="#" className="nav-link">
                          Services
                        </a>
                        <ul className="dropdown-menu">
                          <li className="nav-item">
                            <a href="#" className="nav-link">
                              Web 3 Development
                            </a>
                          </li>
                          <li className="nav-item">
                            <a href="#" className="nav-link">
                              Blockchain Development
                            </a>
                          </li>
                          <li className="nav-item">
                            <a href="#" className="nav-link">
                              NFT Development
                            </a>
                          </li>
                          <li className="nav-item">
                            <a href="#" className="nav-link">
                              Mobile App Development
                            </a>
                          </li>
                          <li className="nav-item">
                            <a href="#" className="nav-link">
                              PWA Development
                            </a>
                          </li>
                          <li className="nav-item">
                            <a href="#" className="nav-link">
                              Web App Development
                            </a>
                          </li>
                          <li className="nav-item">
                            <a href="#" className="nav-link">
                              Product Prototype
                            </a>
                          </li>
                          <li className="nav-item">
                            <a href="#" className="nav-link">
                              Cloud And DevOps
                            </a>
                          </li>
                          <li className="nav-item">
                            <a href="#" className="nav-link">
                              Software Testing
                            </a>
                          </li>
                          <li className="nav-item">
                            <a href="#" className="nav-link">
                              Digital Marketing
                            </a>
                          </li>
                        </ul>
                        <a
                          className="mean-expand"
                          href="#"
                          id="navbarDropdown"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          +
                        </a>
                      </li>
                      <li className="nav-item mean-last">
                        <NavLink to="/contact" className="nav-link">
                          Contact
                        </NavLink>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className="logo">
                  <a href="./">
                    <img
                      src="assets/images/ulticus-logo-welcome-to-innovate-sagar-jain-world_s-no.1-guaranteed-roi-it-company-main-light.png"
                      alt="ulticus-logo-welcome-to-innovate-sagar-jain-world_s - no.1-guaranteed-roi-it-company-main"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="main-navbar navbar-with-black-color">
            <div className="container-fluid">
              <nav className="navbar navbar-expand-md navbar-light">
                <a className="navbar-brand" href="./">
                  <img
                    src="assets/images/ulticus-logo-welcome-to-innovate-sagar-jain-world_s-no.1-guaranteed-roi-it-company-main-light.png"
                    alt="ulticus-logo-welcome-to-innovate-sagar-jain-world_s - no.1-guaranteed-roi-it-company-main"
                  />
                </a>
                <div
                  className="collapse navbar-collapse mean-menu"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav ms-auto">
                    <li className="nav-item">
                      <NavLink to="/" className="nav-link">
                        Home
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <a href="#" className="nav-link">
                        Services
                      </a>
                      <ul className="dropdown-menu">
                        <li className="nav-item">
                          <a href="#" className="nav-link">
                            Web 3 Development
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="#" className="nav-link">
                            Blockchain Development
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="#" className="nav-link">
                            NFT Development
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="#" className="nav-link">
                            Mobile App Development
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="#" className="nav-link">
                            PWA Development
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="#" className="nav-link">
                            Web App Development
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="#" className="nav-link">
                            Product Prototype
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="#" className="nav-link">
                            Cloud And DevOps
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="#" className="nav-link">
                            Software Testing
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="#" className="nav-link">
                            Digital Marketing
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/about" className="nav-link">
                        About
                      </NavLink>
                    </li>

                    <li className="nav-item active">
                      <NavLink to="/contact" className="nav-link">
                        Contact
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
        {/* End Navbar Area */}
        {/* Start Page Banner Area */}
        <div
          className="page-banner-area bg-5 jarallax"
          data-jarallax='{"speed": 0.3}'
        >
          <div className="container">
            <div
              className="page-banner-content"
              // data-aos="fade-right"
              // data-aos-delay={50}
              // data-aos-duration={500}
              data-aos-once="true"
            >
              <h2>Contact us</h2>
              <ul>
                <li>
                  <a href="./">Home</a>
                </li>
                <li>Contact</li>
              </ul>
            </div>
          </div>
        </div>
        {/* End Page Banner Area */}
        {/* Start Talk Area */}
        <div className="talk-area ptb-100">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="talk-image">
                  <img
                    src="https://templates.envytheme.com/coze/default/assets/images/talk/talk.png"
                    alt="image"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="talk-content margin-zero">
                  <span>LET'S TALK</span>
                  <h3>
                    We Would Like To Hear From You Anytime <span className="" />
                  </h3>
                  <form id="contactFormTwo" ref={form} onSubmit={sendEmail}>
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            required
                            data-error="Please enter your name"
                            placeholder="Your name"
                          />
                          <div className="help-block with-errors" />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            required
                            data-error="Please enter your email"
                            placeholder="Your email address"
                          />
                          <div className="help-block with-errors" />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="subject"
                            className="form-control"
                            required
                            data-error="Please enter your subject"
                            placeholder="Your Subject"
                          />
                          <div className="help-block with-errors" />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <input
                            type="number"
                            name="number"
                            className="form-control"
                            required
                            data-error="Please enter your phone"
                            placeholder="Your Phone"
                          />
                          <div className="help-block with-errors" />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <textarea
                            name="message"
                            className="form-control"
                            cols={30}
                            rows={6}
                            required
                            data-error="Please enter your message"
                            placeholder="Your message..."
                            defaultValue={""}
                          />
                          <div className="help-block with-errors" />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <button
                          type="submit"
                          name="cancelCourse"
                          // onClick={cancelCourse}
                          className="default-btn"
                        >
                          Send Message
                          <span />
                        </button>
                        <span className="msg">
                          {done && "Thank you for contact us !"}
                        </span>
                        <div
                          id="msgSubmitTwo"
                          className="h3 text-center hidden"
                        />
                        <div className="clearfix" />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Talk Area */}
        {/* Start Map Area */}
        <div className="container ptb-100">
          <div className="map-location">
            <iframe src="https://maps.google.com/maps?q=1101%20E%20Cumberland%20Ave%20201H-312,%20Tampa,%20FL%2033602&t=&z=13&ie=UTF8&iwloc=&output=embed" />
          </div>
        </div>
        {/* End Map Area */}
        {/* End Footer Area */}
        <footer className="footer-area with-black-background margin-zero pt-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-3 col-md-6">
                <div
                  className="single-footer-widget"
                  // data-aos="fade-up"
                  // data-aos-delay={50}
                  // data-aos-duration={500}
                  data-aos-once="true"
                >
                  <div className="widget-logo">
                    <a href="./">
                      <img
                        src="assets/images/ulticus-logo-welcome-to-innovate-sagar-jain-world_s-no.1-guaranteed-roi-it-company-main-light.png"
                        alt="image"
                      />
                    </a>
                  </div>
                  <p>
                    Ultimate Infoways invites the most innovative minds, the
                    most talented individuals and the most passionate achievers
                    to join us as we strive to be the best in the web content
                    management, marketing automation and enterprise eCommerce
                    spaces
                  </p>
                  <ul className="widget-social">
                    <li>
                      <a
                        href="https://www.facebook.com/EnvyTheme"
                        target="_blank"
                      >
                        <i className="ri-facebook-fill" />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/?lang=en" target="_blank">
                        <i className="ri-twitter-fill" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/" target="_blank">
                        <i className="ri-youtube-fill" />
                      </a>
                    </li>
                    <li>
                      <a href="https://vimeo.com/" target="_blank">
                        <i className="ri-vimeo-fill" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/" target="_blank">
                        <i className="ri-instagram-line" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div
                  className="single-footer-widget ps-5"
                  // data-aos="fade-up"
                  // data-aos-delay={70}
                  // data-aos-duration={700}
                  data-aos-once="true"
                >
                  {/* <h3>Pages</h3> */}
                  <ul className="quick-links">
                    {/* <li><a href="contact.html">Contact Us</a></li>
                            <li><a href="purchase-guide.html">Purchase Guide</a></li>
                            <li><a href="faq.html">FAQ's</a></li>
                            <li><a href="terms-of-service.html">Terms of Service</a></li>
                            <li><a href="privacy-policy.html">Privacy Policy</a></li> */}
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div
                  className="single-footer-widget"
                  // data-aos="fade-up"
                  // data-aos-delay={80}
                  // data-aos-duration={800}
                  data-aos-once="true"
                >
                  <h3>Subscribe Newsletter</h3>
                  <form className="newsletter-form" data-bs-toggle="validator">
                    <input
                      type="email"
                      className="input-newsletter"
                      placeholder="Enter your email"
                      name="EMAIL"
                      required
                      autoComplete="off"
                    />
                    <button type="submit" className="default-btn">
                      Subscribe
                    </button>
                    <div id="validator-newsletter" className="form-result" />
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright-area">
            <div className="container">
              <div className="copyright-area-content">
                <p>
                  Copyright @ Ultimate Infoways All Rights Reserved
                  <a href="https://envytheme.com/" target="_blank">
                    {" "}
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="footer-shape-1">
            <img src="assets/images/footer/footer-shape-1.png" alt="image" />
          </div>
          <div className="footer-shape-2">
            <img src="assets/images/footer/footer-shape-2.png" alt="image" />
          </div>
          <div className="footer-shape-3">
            <img src="assets/images/footer/footer-shape-3.png" alt="image" />
          </div>
        </footer>
      </div>
    </div>
  );
}

export default Contact;
